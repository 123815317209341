import { ActionTree } from 'vuex';
import { AkimatsState, AkimatTree } from '@/store/akimats/types';
import { RootState } from '@/store/types';
import services from '@/api/services';
import { Akimat } from '@/api/services/AkimatsService/types';

export const actions: ActionTree<AkimatsState, RootState> = {
  async FETCH_ALL({ commit }) {
    try {
      const response = await services.akimats.getList();

      const map = (akimat: Akimat): AkimatTree => {
        return {
          ...akimat,
          ...(akimat.nestedAkimats.length && {
            children: akimat.nestedAkimats.map((el) => map(el)),
          }),
        };
      };

      commit('setTree', map(response.data));
    } catch (e) {
      console.error(e);
    }
  },
};
