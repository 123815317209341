import { ActionTree } from 'vuex';
import { BudgetInfoState } from '@/store/budget-info/types';
import { RootState } from '@/store/types';
import services from '@/api/services';
import {
  ReportApprovement,
  ReportComparisonProps,
  ReportComparisonRatingProps,
  SaveableReportProps,
  Subject,
  TaxpayerType,
} from '@/api/services/ReportsService/types';
import { PageableQueryParams } from '@/types/pagination';
import { tree } from '@/types/tree';
import { AkimatTree } from '@/store/akimats/types';

export const actions: ActionTree<BudgetInfoState, RootState> = {
  async FETCH_COMPARISON(
    { commit },
    props: Omit<ReportComparisonProps, 'reportSubject' | 'taxPayerType'>,
  ) {
    try {
      commit('setFetchStatus', true);
      const response = await services.reports.getComparison({
        ...props,
        taxPayerType: TaxpayerType.FL,
        reportSubject: Subject.TAX_PAYMENTS,
      });
      commit('setData', response.data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setFetchStatus', false);
    }
  },
  async FETCH_COMPARISON_RATING(
    { commit, rootGetters },
    filters: PageableQueryParams,
  ) {
    try {
      commit('setFetchStatus', true);
      const props: ReportComparisonRatingProps = {
        subject: Subject.TAX_PAYMENTS,
        reportYear: 2022,
        taxPayerType: TaxpayerType.FL,
        akimatIds: tree(
          (rootGetters['akimats/userTree'] as AkimatTree).children || [],
        )
          .get()
          .map((akimat) => akimat.code)
          .join(','),
        ...filters,
      };
      const response = await services.reports.getComparisonRating(props);
      commit('setRating', response.data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setFetchStatus', false);
    }
  },
  async UPLOAD(ctx, props: SaveableReportProps) {
    try {
      const response = await services.reports.save(props);
      return response.status === 200;
    } catch (e) {
      console.error(e);
    }
  },
  async APPROVE_UPLOAD(ctx, props?: ReportApprovement) {
    if (!props) return false;
    try {
      const response = await services.reports.approveSave(props);
      return response.status === 200;
    } catch (e) {
      console.error(e);
    }
  },
  async CANCEL_UPLOAD(ctx, props?: ReportApprovement) {
    if (!props) return false;
    try {
      const response = await services.reports.cancelSave(props);
      return response.status === 200;
    } catch (e) {
      console.error(e);
    }
  },
  async FETCH_HISTORY({ commit }, params?: PageableQueryParams) {
    try {
      commit('setFetchStatus', true);
      const response = await services.reports.getHistory(params);
      commit('setHistory', response.data);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setFetchStatus', false);
    }
  },
  async FETCH_DETAILED_COMPARISON(
    { commit },
    props: PageableQueryParams & Omit<ReportComparisonProps, 'reportSubject'>,
  ) {
    try {
      commit('setFetchStatus', true);
      const response = await services.reports.getDetailedComparison({
        ...props,
        reportSubject: Subject.TAX_PAYMENTS,
      });
      commit('setDetailedData', response.data);
    } catch (e) {
      commit('setDetailedData', { content: [] });
      console.error(e);
    } finally {
      commit('setFetchStatus', false);
    }
  },
};
